import React, { useState } from "react"
import Layout from "../components/layout"

import styles from "./404.module.css"

export default function NotFoundPage() {
    return(
        <Layout mainClass={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.innerWrapper}>
                    <div className={styles.illustration}>&nbsp;</div>
                    <h1>Page not found</h1>
                    <p className="lead">The page you are looking for does not exist or it has been removed</p>
                    <a href="/">Go back to the home</a>
                </div>
            </div>
        </Layout>
    )
}